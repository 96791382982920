import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmComponent } from "../../components/ConfirmComponent";
import { StorePriceComponent } from "../../components/StorePriceComponent";
import { AvailableStoresList } from "../../constant/availableStores";
import { useShoppingList } from "../../hooks/useShoppingList";
import { NutritionInfo } from "../../model/NutritionInfo";
import { Recipe } from "../../model/Recipe";
import {
  createItemGroupWithDesiredWeight,
  getItemGroup,
  getRecipe,
} from "../../services/apiService";
import { useAddItemToShoppinglist } from "../../utils/addItemToShoppinglist";
import { findBestStoreForRecipe } from "../../utils/findBestStoreForRecipe";
import { maxRecipePersons } from "../../utils/validationVariables";

export function RecipeDetailsPage() {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState<Recipe | null>(null);
  const [checkedIngredients, setCheckedIngredients] = useState<boolean[]>([]);
  const [recommendedPersonAmount, setRecommendedPersonAmount] = useState(0);
  const [totalNutritionInfo, setTotalNutritionInfo] =
    useState<NutritionInfo | null>(null);
  const [perPersonNutritionInfo, setPerPersonNutritionInfo] =
    useState<NutritionInfo | null>(null);
  const [totalPrices, setTotalPrices] = useState<number[]>([]);
  const [cheapestPricePerPerson, setCheapestPricePerPerson] =
    useState<number>(0);
  const [minTotalPrice, setMinTotalPrice] = useState<number>(0);
  const [shoppinglistUpdated, setShoppingListUpdated] = useState(false);
  const addItemToShoppinglist = useAddItemToShoppinglist();
  const {
    removeAllItems,
    shoppingList,
    removeItem,
    addToExistingShoppingListItem,
  } = useShoppingList();

  const nutrientKeys = [
    { key: "calories", label: "Kalorier", unit: "kcal" },
    { key: "fat", label: "Fedt", unit: "g" },
    { key: "saturatedFat", label: "Mættet fedt", unit: "g" },
    { key: "carbohydrates", label: "Kulhydrater", unit: "g" },
    { key: "sugars", label: "Sukker", unit: "g" },
    { key: "fiber", label: "Kostfibre", unit: "g" },
    { key: "protein", label: "Protein", unit: "g" },
    { key: "salt", label: "Salt", unit: "g" },
  ] as const;

  useEffect(() => {
    const fetchRecipe = async () => {
      const recipeData = await getRecipe(parseFloat(recipeId));
      if (recipeData) {
        setRecipe(recipeData);
      } else {
        navigate("/recipe");
      }
    };
    fetchRecipe();
  }, [recipeId, navigate]);

  useEffect(() => {
    if (recipe) {
      setCheckedIngredients(new Array(recipe.ingredients.length).fill(true));
      setRecommendedPersonAmount(recipe.recommendedPersonAmount || 0);
      setTotalNutritionInfo(recipe.nutritionInfo);
    }
  }, [recipe]);

  useEffect(() => {
    if (shoppinglistUpdated) {
      addAllIngredientsToShoppingList();
      setShoppingListUpdated(false);
    }
  }, [shoppingList]);

  useEffect(() => {
    if (recipe && recommendedPersonAmount > 0) {
      const fetchPrices = async () => {
        await calculateTotalPrices();
      };
      fetchPrices();
    }
  }, [recipe, recommendedPersonAmount]);

  useEffect(() => {
    if (totalPrices.length > 0 && recommendedPersonAmount > 0) {
      const validPrices = totalPrices.filter(
        (price) => price != null && price > 0
      );
      if (validPrices.length > 0) {
        const minTotalPrice = Math.min(...validPrices);
        const pricePerPerson = minTotalPrice / recommendedPersonAmount;
        setMinTotalPrice(minTotalPrice);
        setCheapestPricePerPerson(pricePerPerson);
      } else {
        setCheapestPricePerPerson(0);
      }
    }
  }, [totalPrices, recommendedPersonAmount]);

  useEffect(() => {
    if (totalNutritionInfo && recommendedPersonAmount > 0 && recipe) {
      const nutrientKeys = [
        "calories",
        "fat",
        "saturatedFat",
        "carbohydrates",
        "sugars",
        "fiber",
        "protein",
        "salt",
      ] as const;
      const perPersonInfo: NutritionInfo = {} as NutritionInfo;

      // Adjust totalNutritionInfo based on the scaling factor
      const scalingFactor =
        recommendedPersonAmount / recipe.recommendedPersonAmount;

      nutrientKeys.forEach((key) => {
        perPersonInfo[key] =
          (totalNutritionInfo[key] * scalingFactor) / recommendedPersonAmount;
      });
      setPerPersonNutritionInfo(perPersonInfo);
    }
  }, [totalNutritionInfo, recommendedPersonAmount, recipe]);

  const handleCheckboxChange = (index: number) => {
    setCheckedIngredients((prev) => {
      const newChecked = [...prev];
      newChecked[index] = !newChecked[index];
      return newChecked;
    });
  };

  function confirmBeforeAddingToShoppingList() {
    if (shoppingList.length === 0) {
      addAllIngredientsToShoppingList();
      return;
    }
    ConfirmComponent(
      "Bekræft",
      "Vil du tilføje ingredienserne til din eksisterende indkøbsliste eller vil du oprette en ny indkøbsliste?",
      "Ny indkøbsliste",
      "Eksisterende indkøbsliste",
      async () => {
        setShoppingListUpdated(true);
        removeAllItems();
      },
      async () => {
        await addAllIngredientsToShoppingList();
      }
    );
  }

  async function addAllIngredientsToShoppingList() {
    const ingredientsInShoppingList = shoppingList.filter((item) =>
      recipe?.ingredients.some(
        (ingredient) => ingredient.name === item.itemGroup.name
      )
    );

    if (ingredientsInShoppingList.length > 0) {
      const ingredientNames = ingredientsInShoppingList
        .map((ingredient) => ingredient.itemGroup.name)
        .join(", ");

      ConfirmComponent(
        "Bekræft",
        `${ingredientNames} er allerede i din indkøbsliste. Vil du opdatere vægten på alle varene?`,
        "Opdater",
        "Ikke nu",
        () => {
          ingredientsInShoppingList.forEach((ingredient) => {
            addToExistingShoppingListItem(
              ingredient.itemGroup,
              ingredient.desiredWeight
            );
          });
        },
        () => {
          return;
        }
      );
    } else {
      // If no items are already in the list, add all ingredients normally
      for (const ingredient of recipe.ingredients) {
        const itemGroup = await getItemGroup(ingredient.name);
        const scalingFactor =
          recommendedPersonAmount / recipe.recommendedPersonAmount;
        const adjustedWeight = ingredient.weight * scalingFactor;

        addItemToShoppinglist(itemGroup, adjustedWeight);
      }
    }
  }

  async function calculateTotalPrices() {
    const scalingFactor =
      recommendedPersonAmount / recipe.recommendedPersonAmount;
    const localTotalPrices = new Array(AvailableStoresList.length).fill(0);

    const ingredientPromises = recipe.ingredients.map(async (ingredient) => {
      const adjustedWeight = ingredient.weight * scalingFactor;

      try {
        const itemGroup = await getItemGroup(ingredient.name);

        const itemGroupWithDesiredWeight =
          await createItemGroupWithDesiredWeight(
            adjustedWeight,
            ingredient.unit, // Ensure this unit matches the item's unitsOfMeasure
            itemGroup
          );

        return itemGroupWithDesiredWeight.cheapestItemsForEachStore;
      } catch (error) {
        console.error(
          `Failed to process ingredient ${ingredient.name}:`,
          error
        );
        return null;
      }
    });

    const cheapestItemsList = await Promise.all(ingredientPromises);

    cheapestItemsList.forEach((cheapestItemsForEachStore) => {
      if (cheapestItemsForEachStore) {
        cheapestItemsForEachStore.forEach((storeData, index) => {
          if (storeData.itemsSelected !== null) {
            localTotalPrices[index] += storeData.price;
          }
        });
      }
    });

    setTotalPrices(localTotalPrices);
  }

  return (
    <div className="w-full h-full flex flex-row">
      <div className="flex flex-col w-1/3 h-full p-3 justify-center items-center">
        <div className="flex flex-col w-full justify-start items-start">
          <button
            className="relative top-0 left-0 size-14"
            onClick={() => navigate(`/recipe`)}
          >
            <img src="/icon/back_arrow.png" alt="" />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center h-1/2 p-2">
          <img className="w-full m-5 max-h-60" src={recipe?.image} />
          <div className="flex flex-col items-center bg-lightgreyBackground rounded-full p-2 mb-5 w-full">
            <h2 className="text-2xl font-bold text-center">
              {recipe?.recipeName}
            </h2>
            <div className="flex items-center mt-1">
              <img
                src="/icon/recipes_page/time.png"
                alt="Time"
                className="h-6 mr-1"
              />
              <span className="text-lg">{recipe?.time} min</span>
            </div>
            <div className="flex items-center w-fit h-8">
              <img src="/icon/payment.png" alt="Time" className="h-5 mr-2" />
              <p className="text-lg">
                {recipe &&
                  (() => {
                    const { hasMissingIngredients } =
                      findBestStoreForRecipe(recipe);
                    return minTotalPrice > 0
                      ? `${(minTotalPrice / 100).toFixed(2)} ${
                          hasMissingIngredients ? "*" : " "
                        }kr`
                      : "N/A";
                  })()}
              </p>
            </div>
          </div>
        </div>
        <div
          id="nutrition_div"
          className="flex flex-col w-full h-1/2 p-3 bg-lightgreyBackground rounded-lg"
        >
          <h2 className="font-bold">Næringsindhold pr. person</h2>
          {perPersonNutritionInfo && (
            <div className="flex flex-col mt-2">
              {nutrientKeys.map((nutrient) => (
                <div
                  key={nutrient.key}
                  className="flex flex-row justify-between"
                >
                  <span>{nutrient.label}</span>
                  <span>
                    {perPersonNutritionInfo[nutrient.key].toFixed(1)}{" "}
                    {nutrient.unit}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-3/12 h-full p-3 justify-start items-center">
        <div className="flex w-full h-full rounded-lg p-5 bg-lightgreyBackground">
          <div>
            <h2 className="font-bold mb-2">Beskrivelse</h2>
            <p>{recipe?.description}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-5/12 h-full p-3 justify-between items-start pr-10">
        <div
          id="recipe_ingredients"
          className="flex flex-col w-full h-full justify-between border-t border-darkgreyBackground"
        >
          <div className="flex flex-col w-full h-fit justify-start items-start">
            {recipe?.ingredients.map((ingredient, index) => {
              // Calculate adjusted weight
              const scalingFactor =
                recommendedPersonAmount / recipe.recommendedPersonAmount;
              const adjustedWeight = ingredient.weight * scalingFactor;

              return (
                <div
                  key={index}
                  className="flex flex-row w-full h-fit justify-between items-center p-2 border-b border-darkgreyBackground"
                >
                  <div className="flex justify-start basis-1/4 flex-grow">
                    <p className="text-lg font-bold">{ingredient.name}</p>
                  </div>
                  <p className="flex justify-center items-center h-fit w-3/12 text-lg py-2 px-3 mx-2 bg-lightgreyBackground rounded-full">
                    {adjustedWeight.toFixed(1)} {ingredient.unit}
                  </p>
                  <input
                    className="size-7"
                    type="checkbox"
                    checked={checkedIngredients[index]} // Controlled checkbox state
                    onChange={() => handleCheckboxChange(index)} // Handle checkbox change
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-between items-center border-t border-darkgreyBackground">
            <div className="flex items-center">
              <div
                id="portion_div"
                className="flex flex-row w-fit h-fit justify-center items-center py-1 px-6 m-2 bg-lightgreyBackground space-x-2 rounded-full"
              >
                <img
                  src="/icon/recipes_page/person.png"
                  className="flex justify-center items-center h-8"
                  alt=""
                />
                <button
                  onClick={() =>
                    setRecommendedPersonAmount((prev) => Math.max(prev - 1, 1))
                  }
                  className="flex justify-center items-center p-1 w-6 bg-cancel rounded-lg"
                >
                  <img
                    className="flex justify-center items-center w-full"
                    src="/icon/substract_sign.png"
                    alt="subtract"
                  />
                </button>
                <input
                  className="w-10 h-full rounded-lg text-center m-0"
                  type="number"
                  value={recommendedPersonAmount}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setRecommendedPersonAmount(
                      value > maxRecipePersons
                        ? maxRecipePersons
                        : value > 0
                        ? value
                        : 1
                    );
                  }}
                />
                <button
                  onClick={() =>
                    setRecommendedPersonAmount((prev) =>
                      prev < maxRecipePersons ? prev + 1 : prev
                    )
                  }
                  className="flex justify-center items-center w-6 p-1 text-lg bg-action rounded-lg"
                >
                  <img
                    className="flex justify-center items-center w-full"
                    src="/icon/add_sign.png"
                    alt="add"
                  />
                </button>
              </div>
              {cheapestPricePerPerson > 0 && (
                <div className="flex items-center ml-2">
                  <img
                    src="../icon/recipes_page/person.png"
                    alt="person"
                    className="flex size-10"
                  ></img>
                  <span className="text-lg ml-2">
                    {(cheapestPricePerPerson / 100).toFixed(2)} kr
                  </span>
                </div>
              )}
            </div>
            <button
              onClick={() => confirmBeforeAddingToShoppingList()}
              className="flex h-10 p-2 justify-center items-center bg-action hover:bg-actionHover text-darkText font-bold rounded-lg"
            >
              Tilføj til din indkøbsliste
            </button>
          </div>
        </div>
        <div
          id="stores_div"
          className="flex flex-row items-start space-x-3 m-2"
        >
          {AvailableStoresList.map((store, index) => (
            <StorePriceComponent
              key={store}
              store={store}
              clickable={false}
              onSelectStore={() => {}}
              isSelected={false}
              totalPrice={totalPrices[index]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
