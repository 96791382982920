import { onAuthStateChanged, onIdTokenChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../utils/firebaseSetup";

const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log("Auth state changed: ", currentUser);
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeAuth();
    };
  }, []);

  return user;
};

export default useAuth;
