import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { firebaseSDK } from "../utils/firebaseSetup";
import { SwiftCartLogo } from "./SwiftCartLogoComponent";

const auth = getAuth(firebaseSDK);

const CustomNavLink = ({ to, children }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex flex-row items-center px-5 py-3 my-1 rounded-md ${
          isActive ? "bg-action" : "bg-darkBackground hover:bg-secondaryHover"
        }`
      }
    >
      {children}
    </NavLink>
  );
};

const CustomNavDiv = ({ children }) => {
  return (
    <div className="flex flex-row px-5 py-3 m-3 rounded-md hover:bg-secondaryHover">
      {children}
    </div>
  );
};

const CustomNavIcon = ({ src, alt }) => {
  return <img src={src} alt={alt} className="size-5 mx-2"></img>;
};

export const NavBarComponent = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function logoutUser() {
    signOut(auth)
      .then(() => {
        console.log("User signed out");

        navigate("/");
        toast.info("Du er nu logget ud");
      })
      .catch((error) => {
        console.error("Error during sign out: ", error);
      });
  }

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-darkBackground flex flex-row h-16 w-screen relative">
      <SwiftCartLogo hasShadow={false} />
      <div className="text-lightText flex flex-row my-2 px-2 space-x-2">
        <CustomNavLink to="/items">
          <CustomNavIcon
            src="/icon/fridge.png"
            alt="fridge icon"
          ></CustomNavIcon>{" "}
          Varer
        </CustomNavLink>
        <CustomNavLink to="/recipe">
          <CustomNavIcon
            src="/icon/recipes.png"
            alt="recipe icon"
          ></CustomNavIcon>{" "}
          Opskrifter
        </CustomNavLink>
        <CustomNavLink to="/shoppingList">
          <CustomNavIcon
            src="/icon/shopping_cart.png"
            alt="shopping cart icon"
          ></CustomNavIcon>{" "}
          Indkøbsliste
        </CustomNavLink>
      </div>
      <div className="flex flex-row ml-auto mx-1">
        <div className="w-px bg-action"></div>

        <CustomNavDiv>
          <button className="flex flex-row items-center text-lightText text-center">
            <CustomNavIcon
              src="/icon/settings.png"
              alt="settings icon"
            ></CustomNavIcon>
            Indstillinger
          </button>
        </CustomNavDiv>
        <CustomNavDiv>
          <button
            onClick={logoutUser}
            className="flex flex-row items-center text-lightText text-center"
          >
            <CustomNavIcon
              src="/icon/logout.png"
              alt="logout icon"
            ></CustomNavIcon>
            Log ud
          </button>
        </CustomNavDiv>
      </div>
    </nav>
  );
};
