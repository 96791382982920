import { useState } from "react";
import { AvailableStoresList } from "../../constant/availableStores";
import { useShoppingList } from "../../hooks/useShoppingList";
import { Item } from "../../model/Item";
import { ShoppingListItemProps } from "../../model/ShoppingListItemProps";
import { filterSelectedItemsToHaveCount } from "../../utils/filterSelectedItemsToHaveCount";

export function ShoppingListItemComponent({
  shoppingListItem,
  choosenStore,
}: ShoppingListItemProps) {
  const chosenStoreIndex = AvailableStoresList.findIndex((store) => {
    return store === choosenStore;
  });

  const { setMarkedItem, updateShoppingListItem } = useShoppingList();
  var count = 0;
  const [desiredWeights, setDesiredWeights] = useState<number>(
    shoppingListItem.desiredWeight
  );

  function buildNewShoppinglistItem() {
    updateShoppingListItem(shoppingListItem.itemGroup, desiredWeights);
  }

  function calculatePricePerKg(items: Item[]) {
    if (!items || items.length === 0) {
      return "0.00";
    }

    // Check if items are measured in "stk"
    if (items[0].unitsOfMeasure.toLowerCase() === "stk") {
      let totalPrice = 0;
      let totalCount = 0;

      for (const item of items) {
        totalPrice += item.discountPrice;
        totalCount++;
      }

      const pricePerStk = totalPrice / 100 / totalCount;
      return pricePerStk.toFixed(2);
    }

    let totalPrice = 0;
    let totalWeight = 0;

    for (const item of items) {
      totalPrice += item.units * item.pricePerKg;
      totalWeight += item.units;
    }

    const pricePerKg = totalPrice / totalWeight / 100;
    return pricePerKg.toFixed(2);
  }

  return (
    <div
      id="shopping_list_item"
      className="flex flex-row w-full h-fit items-center px-3 p-2 border-b border-lightgreyBackground"
    >
      <input
        type="checkbox"
        checked={shoppingListItem.marked}
        className="w-8 h-8 mr-5"
        onChange={() => setMarkedItem(shoppingListItem.itemGroup.id)}
      />
      <div className="flex basis-1/4 flex-grow flex-col min-w-1/6">
        <p className="font-bold text-xl">
          {shoppingListItem?.itemGroup?.name || "Unnamed Item"}
        </p>
        <div className="flex flex-row w-fit">
          <input
            className="text-lightgreytxt p-1 mx-2 rounded-lg w-14 h-fit border border-lightgreyBackground"
            value={desiredWeights}
            onChange={(e) => {
              setDesiredWeights(Number(e.target.value));
            }}
            onBlur={() => {
              buildNewShoppinglistItem();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                buildNewShoppinglistItem();
              }
            }}
          />
          <p className="text-lightgreytxt flex-grow">
            {shoppingListItem.itemGroup.RecommendedItem.unitsOfMeasure}
          </p>
        </div>
      </div>
      <p className="pr-5 w-fit text-xl">{choosenStore}</p>

      <div className="flex flex-row justify-center items-center h-fit w-5/12 min-w-40 bg-mediumgreyBackground rounded-2xl px-1">
        {(() => {
          // Get the filtered items
          const filteredItems = filterSelectedItemsToHaveCount(
            shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex]
              .itemsSelected
          );

          // Calculate accumulated weight
          const accumulatedWeight = filteredItems.reduce(
            (accumulatedWeight, item) =>
              accumulatedWeight + item.units * item.count,
            0
          );

          const pricePerKg = calculatePricePerKg(filteredItems);

          return (
            <div className="flex flex-col px-4 w-3/12 min-w-28">
              <p>
                {accumulatedWeight}{" "}
                {filteredItems[0] && filteredItems[0].unitsOfMeasure === "stk"
                  ? "stk"
                  : "g"}{" "}
              </p>
              <p className="text-gray-600 italic">
                {pricePerKg ? pricePerKg : 0}{" "}
                {filteredItems[0] && filteredItems[0].unitsOfMeasure === "stk"
                  ? "kr/stk"
                  : "kr/kg"}
              </p>
            </div>
          );
        })()}

        <div className="flex flex-col h-fit w-9/12 mx-1">
          {shoppingListItem.cheapestItemsForEachStore.length > 0 &&
            (() => {
              const storeData =
                shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex];
              const itemsSelected = storeData?.itemsSelected;

              // Check if itemsSelected is valid before proceeding
              if (!itemsSelected || itemsSelected.length === 0) {
                console.log(
                  "No items selected or itemsSelected is null/undefined."
                );
                return (
                  <div className="flex flex-row h-full w-full bg-lightgreyBackground hover:bg-lightgreyBackgroundHover rounded-full p-2 align items-center shadow-lg justify-center">
                    <p className="w-fit text-darkBackground font-bold text-center text-lg">
                      Denne vare er ikke i {choosenStore}
                    </p>
                  </div>
                );
              }

              const filteredItems =
                filterSelectedItemsToHaveCount(itemsSelected);

              // Map over the filtered items and render them
              return filteredItems.map((chosenItem, index) => (
                <div
                  key={index}
                  className="flex flex-row h-full w-full bg-lightgreyBackground hover:bg-lightgreyBackgroundHover rounded-full p-1 my-1.5 space-x-4 align items-center shadow-lg"
                >
                  <img
                    className="w-12 h-12 overflow-hidden ml-5 max-w-14 shadow-lg"
                    src={chosenItem.image || "default-image-path.jpg"}
                  />
                  <div className="flex flex-grow flex-col overflow-hidden">
                    <p className="font-bold text-darkBackground">
                      {chosenItem.name}
                    </p>
                    <p>
                      {chosenItem.units} {chosenItem.unitsOfMeasure}
                    </p>
                  </div>
                  <div>
                    <p className="text-darkBackground">
                      {(chosenItem.discountPrice / 100).toFixed(2) || 0} kr
                    </p>
                  </div>
                  <div className="flex bg-darkgreyBackground w-10 h-10 rounded-full justify-center items-center">
                    <p className="font-bold text-darkgreytxt p-4">
                      {chosenItem.count}
                    </p>
                  </div>
                </div>
              ));
            })()}
        </div>
      </div>

      {/* Display the price */}
      <div className="flex h-16 w-28 px-4 mx-2 relative right-0 bg-secondary rounded-full justify-center items-center">
        <p className="font-bold text-xl text-darkBackground">
          {(
            shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex]
              ?.price / 100
          ).toFixed(2) || 0}{" "}
          kr
        </p>
      </div>
    </div>
  );
}
