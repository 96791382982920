import { createContext, ReactNode, useEffect, useState } from "react";
import { AvailableStoresList } from "../constant/availableStores";
import { ItemGroup } from "../model/ItemGroup";
import { ItemGroupWithDesiredWeight } from "../model/ItemGroupWithDesiredWeight";
import { createItemGroupWithDesiredWeight } from "../services/apiService";
import { toast } from "react-toastify";

// Define the context's structure
interface ShoppingListContextType {
  shoppingList: ItemGroupWithDesiredWeight[];
  addItem: (item: ItemGroupWithDesiredWeight) => void;
  updateShoppingListItem: (item: ItemGroup, desiredWeight: number) => void;
  addToExistingShoppingListItem: (
    item: ItemGroup,
    addedDesiredWeight: number
  ) => void;
  removeItem: (itemId: number) => void;
  clearList: () => void;
  removeAllItems: () => Promise<void>;
  isSomethingMarked: () => boolean;
  setMarkedItem: (itemId: number) => void;
  totalPrice: number[];
}

// Create the context with default values
export const ShoppingListContext = createContext<
  ShoppingListContextType | undefined
>(undefined);

export const ShoppingListProvider = ({ children }: { children: ReactNode }) => {
  const [shoppingList, setShoppingList] = useState<
    ItemGroupWithDesiredWeight[]
  >([]);
  const [totalPrice, setTotalPrice] = useState<number[]>([]);

  // Automatically assign an ID when adding an item
  const addItem = (item: ItemGroupWithDesiredWeight) => {
    setShoppingList((prev) => [...prev, item]);
    let localTotalPrice = [];
    totalPrice.forEach((price, index) => {
      if (!price) {
        localTotalPrice[index] = 0;
      }
    });
    toast.success(
      item.desiredWeight +
        item.itemGroup.RecommendedItem.unitsOfMeasure +
        " " +
        item.itemGroup.name +
        " er blevet tilføjet til indkøbskurven"
    );
    setTotalPrice(localTotalPrice);
  };

  useEffect(() => {
    calculateTotalPriceOfShoppinglist();
  }, [shoppingList]);

  const removeItem = (itemId: number) => {
    setShoppingList((prev) =>
      prev.filter((item) => item.itemGroup.id !== itemId)
    );
    toast.info("Vare fjernet fra indkøbskurven");
  };

  const removeAllItems = async () => {
    return new Promise<void>((resolve) => {
      setShoppingList([]);
      resolve(); // Resolve immediately after clearing the list
    });
  };

  const clearList = () => {
    shoppingList.forEach((item) => {
      if (item.marked) {
        removeItem(item.itemGroup.id);
      }
    });
  };

  const setMarkedItem = (itemId: number) => {
    setShoppingList((prev) =>
      prev.map((item) => {
        if (item.itemGroup.id === itemId) {
          return { ...item, marked: !item.marked };
        }
        return item;
      })
    );
  };

  const isSomethingMarked = () => {
    const marked = shoppingList.some((item) => {
      if (item.marked) {
        console.log("isMarked: ", item.marked);
        return true;
      }
      return false;
    });
    return marked;
  };

  function calculateTotalPriceOfShoppinglist() {
    let localTotalPrice = [];
    if (shoppingList.length === 0) {
      AvailableStoresList.forEach((store, index) => {
        if (
          localTotalPrice[index] === undefined ||
          localTotalPrice[index] === null
        ) {
          localTotalPrice[index] = 0;
        }
      });
      setTotalPrice(localTotalPrice);
      return;
    }
    console.log("shoppinglist: ", shoppingList);
    console.log(
      "cheapestItemsForEachStore: ",
      shoppingList[0].cheapestItemsForEachStore
    );

    for (
      let shoppingListItem = 0;
      shoppingListItem < shoppingList.length;
      shoppingListItem++
    ) {
      console.log(
        "length:",
        shoppingList[shoppingListItem].cheapestItemsForEachStore.length
      );

      for (
        let shopIndex = 0;
        shopIndex <
        shoppingList[shoppingListItem].cheapestItemsForEachStore.length;
        shopIndex++
      ) {
        if (
          shoppingList[shoppingListItem].cheapestItemsForEachStore[shopIndex]
            .itemsSelected === null
        ) {
          continue;
        }
        if (
          localTotalPrice[shopIndex] === undefined ||
          localTotalPrice[shopIndex] === null
        ) {
          localTotalPrice[shopIndex] = 0;
        }
        localTotalPrice[shopIndex] +=
          shoppingList[shoppingListItem].cheapestItemsForEachStore[
            shopIndex
          ].price;
        console.log("localTotalPrice[shopIndex]: ", localTotalPrice[shopIndex]);
      }
    }

    setTotalPrice(localTotalPrice);
  }

  const updateShoppingListItem = async (
    item: ItemGroup,
    desiredWeight: number
  ) => {
    const itemGroupIndex = shoppingList.findIndex(
      (itemGroup) => itemGroup.itemGroup.id === item.id
    );

    // Remove the old item and add the updated item with the new weight
    removeItem(shoppingList[itemGroupIndex].itemGroup.id);
    const updatedItemGroupWIthDesiredWeight =
      await createItemGroupWithDesiredWeight(
        desiredWeight,
        item.RecommendedItem.unitsOfMeasure,
        item
      );
    addItem(updatedItemGroupWIthDesiredWeight);
  };

  const addToExistingShoppingListItem = async (
    item: ItemGroup,
    addedDesiredWeight: number
  ) => {
    const itemGroupIndex = shoppingList.findIndex(
      (itemGroupLocal) => itemGroupLocal.itemGroup.id === item.id
    );
    // Get the current desired weight and update it
    const updatedWeight =
      shoppingList[itemGroupIndex].desiredWeight + addedDesiredWeight;

    updateShoppingListItem(item, updatedWeight);
  };

  return (
    <ShoppingListContext.Provider
      value={{
        shoppingList,
        addItem,
        removeItem,
        clearList,
        setMarkedItem,
        isSomethingMarked,
        totalPrice,
        removeAllItems,
        updateShoppingListItem,
        addToExistingShoppingListItem,
      }}
    >
      {children}
    </ShoppingListContext.Provider>
  );
};
