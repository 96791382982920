import React, { useEffect, useState } from "react";
import ItemGroupModal from "../../components/ItemGroup/ItemGroupModalComponent";
import ItemGroupSection from "../../components/ItemGroup/ItemGroupSectionComponent";
import SearchBar from "../../components/SearchBarComponent";
import { StorePriceComponent } from "../../components/StorePriceComponent";
import { AvailableStoresList } from "../../constant/availableStores";
import { useShoppingList } from "../../hooks/useShoppingList";
import { ItemGroup } from "../../model/ItemGroup";
import { getItemGroups } from "../../services/apiService";
import { filterSelectedItemsToHaveCount } from "../../utils/filterSelectedItemsToHaveCount";

const ItemGroupsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemGroups, setItemGroups] = useState<ItemGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedItemGroup, setSelectedItemGroup] = useState<ItemGroup | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [markedStore, setMarkedStore] = useState("Rema");
  const { shoppingList, totalPrice } = useShoppingList();
  const [chosenStoreIndex, setChosenStoreIndex] = useState(0);

  useEffect(() => {
    const index = AvailableStoresList.findIndex(
      (store) => store === markedStore
    );
    setChosenStoreIndex(index);
  }, [markedStore]);

  useEffect(() => {
    const fetchItemGroups = async () => {
      if (searchTerm.trim() === "") {
        setItemGroups([]);
        return;
      }

      if (searchTerm.length > 50) {
        setError("Search term cannot exceed 50 characters.");
        setItemGroups([]);
        return;
      }

      setLoading(true);
      setError("");
      try {
        const data = await getItemGroups(searchTerm);
        setItemGroups(data);
      } catch (err: any) {
        setError("Failed to fetch item groups.");
      } finally {
        setLoading(false);
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchItemGroups();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  // Group item groups by section
  const groupedItemGroups = itemGroups.reduce<{
    [section: string]: ItemGroup[];
  }>((groups, itemGroup) => {
    const section = itemGroup.section || "Other";
    if (!groups[section]) {
      groups[section] = [];
    }
    groups[section].push(itemGroup);
    return groups;
  }, {});

  const handleCardClick = (itemGroup: ItemGroup) => {
    setSelectedItemGroup(itemGroup);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItemGroup(null);
  };

  return (
    <div className="flex flex-row px-5 w-full h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-col w-9/12 ml-5">
        <div className="flex items-end h-20 w-full bg-white fixed z-10">
          <div className="w-8/12 h-14">
            <SearchBar
              onChange={setSearchTerm}
              placeholder="Søg efter vare..."
            />
          </div>
        </div>
        <div className="h-full w-full text-darkText">
          {loading && (
            <p className="text-center mt-4 text-darkText">Loading...</p>
          )}
          {error && <p className="text-center mt-4 text-red-500">{error}</p>}

          {!loading &&
            !error &&
            Object.keys(groupedItemGroups).length === 0 && (
              <p className="text-center mt-4 text-darkText">
                No item groups found.
              </p>
            )}

          {!loading && !error && (
            <div className="flex flex-col relative top-7">
              {Object.keys(groupedItemGroups).map((section) => (
                <ItemGroupSection
                  key={section}
                  section={section}
                  itemGroups={groupedItemGroups[section]}
                  onCardClick={handleCardClick}
                />
              ))}
            </div>
          )}
          {isModalOpen && selectedItemGroup && (
            <ItemGroupModal
              itemGroup={selectedItemGroup}
              onClose={closeModal}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center fixed m-0 right-0 w-4/12 min-w-52 h-[calc(100%-4rem)] py-1 z-20 bg-lightgreyBackground">
        <div className="flex flex-col w-full h-full overflow-y-auto mb-2">
          {shoppingList.map((shoppingListItem) => {
            const storeData =
              shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex];
            const itemsSelected = storeData?.itemsSelected;

            if (!itemsSelected || itemsSelected.length === 0) {
              return (
                <div
                  className="flex flex-row h-14 w-full bg-lightgreyBackground hover:bg-lightgreyBackgroundHover p-1 space-x-4 align items-center border border-y-darkgreyBackground"
                  key={shoppingListItem.itemGroup.id}
                >
                  <p className="w-full text-darkBackground font-bold text-center text-lg">
                    Denne vare er ikke i {markedStore}
                  </p>
                </div>
              );
            }

            const filteredItems = filterSelectedItemsToHaveCount(itemsSelected);

            return filteredItems.map((chosenItem, index) => (
              <div
                key={`${shoppingListItem.itemGroup.id}-${index}`}
                className="flex flex-row h-14 w-full bg-lightgreyBackground hover:bg-lightgreyBackgroundHover p-1 space-x-4 align items-center border border-y-darkgreyBackground"
              >
                <div className="flex bg-darkgreyBackground w-10 h-10 rounded-full justify-center items-center">
                  <p className="font-bold text-darkgreytxt p-4">
                    {chosenItem.count}
                  </p>
                </div>
                <div className="flex flex-grow flex-col overflow-hidden">
                  <p className="font-bold text-darkBackground">
                    {chosenItem.name}
                  </p>
                </div>
                <div>
                  <p className="text-darkBackground">
                    {(chosenItem.discountPrice / 100).toFixed(2) || 0} kr
                  </p>
                </div>
              </div>
            ));
          })}
        </div>
        <div className="bg-darkgreyBackground w-10/12 h-1/12 m-3 flex justify-center items-center rounded-full mt-auto">
          <p className="text-3xl p-1 font-bold text-darkText">
            {totalPrice[chosenStoreIndex] / 100 || 0} kr
          </p>
        </div>
        <div
          id="stores_div"
          className="flex flex-row flex-wrap items-start space-x-0 ml-1"
        >
          {AvailableStoresList.map((store, index) => (
            <StorePriceComponent
              key={store}
              store={store}
              clickable={true}
              onSelectStore={setMarkedStore}
              isSelected={markedStore === store}
              totalPrice={totalPrice[index]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemGroupsPage;
